<template>
  <div class="clients-and-partners dark-bg">
    <metainfo>
      <template v-slot:title="{ content }"
        >{{ content }} - iIT Trading!</template
      >
    </metainfo>
    <HeroBlock :image="image" :icon="icon" :title="title" />
    <bread-crumb></bread-crumb>

    <section class="container" v-if="data">
      <h2 class="clients-and-partners__title">{{ data.title }}</h2>

      <div class="clients-and-partners__tabs tabs">
        <ul class="tabs__list" v-if="data">
          <li class="tabs__item">
            <button
              class="tabs__btn"
              @click="showTab = true"
              :class="{ active: showTab }"
            >
              {{ data.tab2_title }}
            </button>
          </li>
          <li class="tabs__item">
            <button
              class="tabs__btn"
              @click="showTab = false"
              :class="{ active: !showTab }"
            >
              {{ data.tab1_title }}
            </button>
          </li>
        </ul>

        <div class="tabs__content" v-if="!showTab">
          <div
            class="clients-and-partners__descr"
            v-html="data.tab1_description"
          ></div>

          <div class="tabs__carousel" v-if="data.tab1_slider">
            <SliderPerColumn
              :data="data.tab1_slider.attachment"
              :active="data.tab1_slider_active"
              perView="4"
              v-if="desktop === true"
            />
            <SliderPerColumn
              :data="data.tab1_slider.attachment"
              :active="data.tab1_slider_active"
              perView="3"
              v-if="ipad === true"
            />
            <SliderPerColumn
              :data="data.tab1_slider.attachment"
              :active="data.tab1_slider_active"
              perView="2"
              v-if="mobile === true"
            />
          </div>
        </div>

        <div class="tabs__content" v-if="showTab">
          <div
            class="clients-and-partners__descr"
            v-html="data.tab2_description"
          ></div>

          <div class="tabs__carousel" v-if="data.tab2_slider">
            <SliderPerColumn
              :data="data.tab2_slider.attachment"
              :active="data.tab2_slider_active"
              perView="4"
              v-if="desktop === true"
            />
            <SliderPerColumn
              :data="data.tab2_slider.attachment"
              :active="data.tab2_slider_active"
              perView="3"
              v-if="ipad === true"
            />
            <SliderPerColumn
              :data="data.tab2_slider.attachment"
              :active="data.tab2_slider_active"
              perView="2"
              v-if="mobile === true"
            />
          </div>
        </div>
      </div>
    </section>
    <FormCommon />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const HeroBlock = defineAsyncComponent(() =>
  import("@/components/HeroBlock.vue")
);
const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
const SliderPerColumn = defineAsyncComponent(() =>
  import("@/components/SliderPerColumn.vue")
);
const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb.vue")
);
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("ClientsAndPartners"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    HeroBlock,
    FormCommon,
    BreadCrumb,
    SliderPerColumn,
  },
  props: {
    perView: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      locale: this.$i18n.locale,
      image: "",
      icon: "",
      title: "",
      window: {
        width: 0,
      },
      showTab: true,
      desktop: false,
      ipad: false,
      mobile: false,
    };
  },
  created() {
    this.getBanner();
    this.getCliAndParPage();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async getCliAndParPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/cli-and-par-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getBanner() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            clear_url,
          config
        );
        this.image = response.data.data[0].image ?? null;
        this.icon = response.data.data[0].icon[0].url ?? null;
        this.title = response.data.data[0].title ?? null;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 1024) {
        (this.desktop = true), (this.ipad = false), (this.mobile = false);
      } else if (this.window.width < 1024 && this.window.width >= 576) {
        (this.desktop = false), (this.ipad = true), (this.mobile = false);
      } else if (this.window.width < 576) {
        (this.desktop = false), (this.ipad = false), (this.mobile = true);
      }
    },
  },
});
</script>

<style lang="scss">
@import "~@/assets/styles/_mixins.scss";

.clients-and-partners {
  color: #fff;

  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);

    pre {
      @media (max-width: 575.98px) {
        white-space: initial;
      }
    }

    span {
      display: block;
      font-weight: 700;
    }

    p {
      &:first-child {
        color: #f9b80e;
        margin-bottom: 5px;
      }
    }
  }

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #f9b80e;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 40, 20);
  }
}

.tabs {
  @include property("margin-bottom", 100, 30);
  overflow: hidden;

  &__carousel {
    position: relative;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    @include property("padding-bottom", 30, 15, true, 768, 320);
    @include property("margin-bottom", 30, 15, true, 768, 320);
    border-bottom: 1px solid rgba(#f9b80e, 0.5);
  }

  &__item {
    &:not(:last-child) {
      position: relative;
      @include property("margin-right", 150, 30, true, 768, 320);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -75px;
        @include property("right", -75, -15, true, 768, 320);
        width: 1px;
        background: #f9b80e;
      }
    }
  }

  &__btn {
    font-weight: 700;
    @include property("font-size", 32, 24);
    line-height: calc(40 / 32);
    color: #afafaf;
    text-transform: uppercase;
    transition: all 0.3s linear;

    &.active {
      color: #f9b80e;
    }
  }

  &__content {
    max-width: 1250px;
    margin: 0 auto;
    transition: all 0.5s linear;
  }
}
</style>
